<template>
	<h1 id="head">
		Arthur is documenting visual culture from today, yesterday and tomorrow.
	</h1>
	<div id="about" class="content-pad">
		<div class="text-wrap">
			<h2>How it Works</h2>
			<p>
				Arthur is a digital exhibition platform with a focus on art and design. We provide the wall
				space, you provide the shows.
			</p>
			<p>
				All works uploaded to Arthur are indexed in our
				<router-link :to="{ name: 'Search' }">catalog</router-link>.
			</p>
			<!-- <p>Some examples:</p>
			<ul>
				<li v-for="(room, i) in exampleRooms" :key="i">
					<router-link :to="{ name: 'Room', params: { id: room.id } }">{{ room.name }}</router-link>
				</li>
			</ul> -->

			<br /><br />

			<h2>How to Join</h2>
			<p>
				Arthur is in private beta and accessible by invitation only.<br class="br-1" />
				If you wish to get early access there's a few ways to skip the line.
			</p>

			<br /><br />

			<FormButton
				:level="1"
				value="Join wait list"
				@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
				class="bump-r"
			/>
			<FormButton
				:level="2"
				value="Skip the line"
				@click="dialogStore.dialogByName('DialogSkipLine')"
			/>
		</div>
	</div>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'

export default {
	name: 'HomeAbout',
	components: { FormButton },
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
}
</script>

<style scoped lang="scss">
// Head
#head {
	font-size: 14vw;
	line-height: 14vw;
	text-align: center;
	padding-top: 1rem;
	overflow: hidden;
}

// About
#about {
	height: calc(100vh - 0.9rem); // Adjusted for footer
	display: flex;
	align-items: center;
	justify-content: center;
	// flex-direction: column;
}
#about .text-wrap {
	max-width: 4.8rem;
	min-height: 3rem;
}

// @media only screen and (max-width: $tablet) {
// 	#head {
// 		font-size: 14vw;
// 		line-height: 14vw;
// 	}
// }
@media only screen and (max-width: 600px) {
	#about br.br-1 {
		display: none;
	}
}
</style>
